<template>
  <div>
    <v-alert
      v-if="rule === 'compact'"
      :bg="bg"
      :bghover="bghover"
      :fsize="fsize"
      :icon="icon"
      iconsize="8"
    >
      <span color="xl">
        {{ message || $t('empty-default-message') }}
      </span>
    </v-alert>
    <div
      v-else
      radius="3xs"
      tpd="3xl"
      :bg="bg"
      :bghover="bghover"
    >
      <div
        class="box"
        gap="2xs"
        direction="column"
        valign="center"
        :pd="resolution.width > resolution.mobile ? `3xl-y` : `xs-y`"
        :fsize="fsize"
      >
        <v-icon fsize="3xl" :icon="icon" />
        <span color="xl" talign="center" break="pre-line">
          {{ message || $t('empty-default-message') }}
        </span>
        <div v-if="home || refresh" mgt="2xs" class="box" halign="center" gap="md">
          <v-btn
            v-if="home"
            color="primary"
            variant="outlined"
            rounded
            :title="$t('go-home')"
            @click="globalFunctions.goHome()"
          >
            <v-icon icon="mdi-music" mgr="2xs" />
            <strong>{{ $t('go-home') }}</strong>
          </v-btn>
          <v-btn
            v-if="refresh"
            color="grey"
            variant="outlined"
            rounded
            :title="$t('refresh')"
            @click="globals.refresh()"
          >
            <v-icon icon="mdi-reload" mgr="2xs" />
            <strong>{{ $t('refresh') }}</strong>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  name: 'global-name',
  setup () {

    // 글로벌 스토어
    const { resolution } = storeToRefs(useGlobalStore())

    // 정상 리턴
    return {
      resolution
    }
  },
  props: {
    rule: {
      type: String,
      default () {
        return ''
      }
    },
    icon: {
      type: String,
      default () {
        return 'mdi-alert-box'
      }
    },
    message: {
      type: String,
      default () {
        return ''
      }
    },
    bg: {
      type: String,
      default () {
        return ''
      }
    },
    bghover: {
      type: String,
      default () {
        return ''
      }
    },
    fsize: {
      type: String,
      default () {
        return ''
      }
    },
    home: {
      type: Boolean,
      default () {
        return false
      }
    },
    refresh: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>